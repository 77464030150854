<template>
	<footer class="loginFooterCon">
		<div class="frameCon" style="padding-bottom: 20px;">
			<ul class="footer_about_link">
				<li>
					<!-- <i class="el-icon-phone"></i> -->
					<span>
						{{$store.state.CusServiceTelText}}
					</span>
				</li>
				<li>
					<!-- <i class="el-icon-message"></i> -->
					<span>admin@hytxusa.com</span>
				</li>
				<!-- <li>
					<router-link :to="{name:'amazonHelpDoc'}">
						<i class="el-icon-s-help"></i>
						<span>{{$t('i18nn_e93cde152c0070f7')}}</span>
					</router-link>
				</li> -->
			</ul>
			<div class="footer_copy">
				&copy;&nbsp;2024&nbsp;HYTX&nbsp;All&nbsp;rights&nbsp;reserved
			</div>
			<ul class="footer_img_link">
				<li>
					<a href="https://www.hytxlogistics.com/about" target="_blank">{{$t('i18nn_b360807dbe727e6e')}}</a>
				</li>
				<li>
					<a href="https://www.hytxlogistics.com/contact" target="_blank">{{$t('i18nn_07c9d82ce42fdb94')}}</a>
				</li>
			</ul>
		</div>
	</footer>
</template>

<script>
	export default {

		data() {
			return {
				aboutLink: {
					abouteUsLinkId: "",
					recruitLinkId: "",
					contactUsLinkId: "",
					transactionLinkId: "",
					lawLinkId: "",
					businessLinkId: "",
				},
			}
		},

		mounted() {},
		methods: {
			//详情
			toPageName(pageName, id) {
				if (id) {
					this.$router.push({
						name: pageName,
						query: {
							'id': id
						}
					});
				} else {
					console.log('id 不存在');
				}

			},

		},
	}
</script>
<style lang='less' scoped>
	
</style>